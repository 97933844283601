<template>
  <button
    class="download-button button"
    :class="{ 'en-button': language === 'en' }"
    data-agl-cvt="6"
    @click="downloadDirectly">
    <img src="/image/ic_button_download@2x.png" alt="Download">
    <span class="button-text">{{ $t(download_text) }}</span>
  </button>
</template>

<script>
import VersionDetail from '../../public/json/VersionDetail.json';

export default {
  name: 'DownloadButton',
  data() {
    return {
      download_text: 'download_text',
    };
  },
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  methods: {
    downloadDirectly() {
      const { url } = VersionDetail;
      window.open(url);
      this.$ba.trackEvent('webDownload', 'click');
      this.$sensors.track('webDownload');
    },
  },
};
</script>

<style scoped lang="less">
  .button {
    height: 0.64rem;
    padding: 0 0.28rem 0 0.2rem;
    margin-right: 0.16rem;
    border-radius: 8px;
    cursor: pointer;
    /*img, .button-text {*/
    /*  vertical-align: middle;*/
    /*}*/
    img {
      height: 60%;
      margin-right: 0.16rem;
    }
    .button-text {
      font-size: 0.24rem;
      font-weight: 500;
      color: #242737;
      line-height: 0.33rem;
    }
  }
  .download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6DE5FF;
    box-shadow: 0rem 0.05rem 0.24rem 0rem rgba(109, 229, 255, 0.24);
    img {
      width: 0.38rem;
      height: 0.38rem;
    }
  }
  .en-button {
    img {
      margin-right: 0.16rem;
    }
    .button-text {
      font-size: 0.21rem;
    }
  }
</style>
