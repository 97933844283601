import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Authority from '../views/Authority.vue';
import Welcome from '../views/welcome/Welcome.vue';
import Purchase from '../views/purchase/Purchase.vue';
import LicenseUpgraded from '../views/license-upgraded/LicenseUpgraded.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase,
  },
  {
    path: '/license_upgraded',
    name: 'LicenseUpgraded',
    component: LicenseUpgraded,
  },
  {
    path: '/privacy_policy',
    component: Authority,
  },
  {
    path: '*',
    redirect: {
      name: 'Home',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
