<template>
  <button
    class="purchase-in-app-button button"
    @click="jumpToPurchase">
    <span class="button-text">{{ $t(app_store_text) }}</span>
  </button>
</template>

<script>
export default {
  name: 'PurchaseInAppButton',
  data() {
    return {
      purchase_in_app_button_text: 'purchase_in_app_button_text',
      app_store_text: 'app_store_text',
    };
  },
  methods: {
    jumpToPurchase() {
      // this.$ba.trackEvent('jumpToPurchase', 'click');
      // this.$sensors.track('jumpToPurchase');
      // this.$router.push('/purchase');
      window.open('https://apps.apple.com/app/fancyclean/id1587301004?mt=12', '_blank');
    },
  },
};
</script>

<style scoped lang="less">
  .purchase-in-app-button {
    height: 0.64rem;
    padding: 0 0.28rem;
    border-radius: 8px;
    cursor: pointer;
    border: 2px solid #6DE5FF;
    background-color: #191C2B;
    /*img, .button-text {*/
    /*  vertical-align: middle;*/
    /*}*/
    .button-text {
      font-size: 0.24rem;
      font-weight: 500;
      color: #6DE5FF;
      line-height: 0.33rem;
      vertical-align: middle;
    }
  }
</style>
