export default function () {
  const userAgent = window.navigator.platform.toLowerCase();
  let result = '';
  if (userAgent.indexOf('mac') !== -1) {
    result = 'macOS';
  } else if (userAgent.indexOf('iphone') !== -1) {
    result = 'iOS';
  } else if (userAgent.indexOf('android') !== -1) {
    result = 'Android';
  } else if (userAgent.indexOf('win') !== -1) {
    result = 'Windows';
  } else {
    result = 'others';
  }
  return result;
}
