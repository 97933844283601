<template>
  <div class="first-screen">
    <img class="app-logo" src="../../../public/image/pic_banner_logo@2x.png" alt="Bg">
    <h1 class="title">{{ $t(welcome_title) }}</h1>
    <div class="function-box">
      <div class="function-list">
        <div
          v-for="item in function_list"
          :key="item.name"
          class="function-item">
          <img class="function-img" :src="item.img" alt="Function image">
          <span class="function-desc">{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-absolute-path,import/no-unresolved */

import smartScan from '/public/image/smart_scan@2x.png';
import systemTrash from '/public/image/system_trash@2x.png';
import appTrash from '/public/image/app_trash@2x.png';
import browserTrash from '/public/image/browser_trash@2x.png';
import trashCan from '/public/image/trash_can@2x.png';
import bigFiles from '/public/image/big_files@2x.png';
import duplicateFiles from '/public/image/duplicate_files@2x.png';
import appManagement from '/public/image/app_management@2x.png';

export default {
  name: 'FirstScreen',
  data() {
    return {
      welcome_title: 'welcome_title',
      function_list: [
        {
          name: 'function_1',
          img: smartScan,
        },
        {
          name: 'function_2',
          img: systemTrash,
        },
        {
          name: 'function_3',
          img: appTrash,
        },
        {
          name: 'function_4',
          img: browserTrash,
        },
        {
          name: 'function_5',
          img: trashCan,
        },
        {
          name: 'function_6',
          img: bigFiles,
        },
        {
          name: 'function_7',
          img: duplicateFiles,
        },
        {
          name: 'function_8',
          img: appManagement,
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
  .first-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1440px;
    margin: 0 auto;
    .app-logo {
      width: 2.56rem;
      height: 2.56rem;
      margin: 1.44rem 0 0.37rem;
    }
    .title {
      margin-bottom: 0.13rem;
      font-size: 44px;
      font-weight: 300;
      color: #FFFFFF;
      line-height: 53px;
    }
    .function-box {
      width: 10.82rem;
      height: 4.52rem;
      background-image: url("../../../public/image/bg_content@2x.png");
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: 100% 100%;
      .function-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 9.44rem;
        margin: 1.03rem auto;
        .function-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 1.4rem;
          margin: 0 0.48rem 0.4rem;
          .function-img {
            width: 0.8rem;
            height: 0.8rem;
          }
          .function-desc {
            font-size: 16px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.8);
            line-height: 22px;
          }
        }
      }
    }
  }
</style>
