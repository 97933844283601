<template>
  <div class="fifth-screen">
    <div class="app-info-box">
      <img class="app-logo-img" src="../../public/image/pic_logo_down@2x.png" alt="App logo">
      <h1 class="app-title">{{ $t(app_title) }}</h1>
      <p class="app-desc">{{ $t(app_description) }}</p>
      <div class="button-box">
        <download-button v-if="$isMac()"></download-button>
        <app-store-button v-if="$isMac()"></app-store-button>
<!--        <purchase-in-app-button v-if="$isMac()"></purchase-in-app-button>-->
        <windows-button v-if="!$isMac()"></windows-button>
      </div>
      <p class="app-info">
        {{ $t(app_detail_1) }}{{ version_detail.date }}&nbsp;
        {{ $t(app_detail_2) }}{{ version_detail.size }}&nbsp;
        {{ $t(app_detail_3) }}{{ version_detail.version }}&nbsp;
        {{ $t(app_detail_4) }}{{ version_detail.compatibility }}{{ $t(app_detail_5) }}
      </p>
    </div>
  </div>
</template>

<script>
import DownloadButton from '../components/DownloadButton.vue';
import VersionDetail from '../../public/json/VersionDetail.json';
import WindowsButton from '../components/WindowsButton.vue';
import PurchaseInAppButton from '../components/PurchaseInAppButton.vue';
import AppStoreButton from '../components/AppStoreButton.vue';

export default {
  name: 'FifthScreen',
  components: {
    AppStoreButton,
    // eslint-disable-next-line vue/no-unused-components
    PurchaseInAppButton,
    DownloadButton,
    WindowsButton,
  },
  data() {
    return {
      app_title: 'app_name',
      app_description: 'app_description',
      app_desc: 'app_description',
      app_detail_1: 'app_detail_1',
      app_detail_2: 'app_detail_2',
      app_detail_3: 'app_detail_3',
      app_detail_4: 'app_detail_4',
      app_detail_5: 'app_detail_5',
      version_detail: VersionDetail,
    };
  },
};
</script>

<style scoped lang="less">
  .fifth-screen {
    max-width: 1280px;
    margin: 0 auto 2rem;
    padding: 0 40px;
    .app-info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -0.62rem;
      .app-logo-img {
        width: 1.6rem;
        height: 1.6rem;
        margin-bottom: 0.37rem;
      }
      .app-title {
        margin-bottom: 0.14rem;
        font-size: 0.44rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 0.53rem;
      }
      .app-desc {
        margin-bottom: 0.34rem;
        font-size: 0.18rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.21rem;
      }
      .button-box {
        display: flex;
        justify-content: center;
        margin-bottom: 0.32rem;
      }
      .app-info {
        font-size: 0.14rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.2rem;
        white-space: pre-wrap;
      }
    }
  }
</style>
