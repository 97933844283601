<template>
  <div class="first-screen">
    <img class="app-logo" src="../../public/image/pic_banner_logo@2x.png" alt="Banner logo">
    <h1 class="app-name">{{ $t(app_name) }}</h1>
    <p class="app-desc">{{ $t(app_desc) }}</p>
    <div class="button-box">
      <download-button v-if="$isMac()"></download-button>
      <app-store-button v-if="$isMac()"></app-store-button>
<!--      <purchase-in-app-button v-if="$isMac()"></purchase-in-app-button>-->
      <windows-button v-if="!$isMac()"></windows-button>
    </div>
    <p class="app-detail">
      {{ $t(app_detail_1) }}{{ version_detail.date }}&nbsp;
      {{ $t(app_detail_2) }}{{ version_detail.size }}&nbsp;
      {{ $t(app_detail_3) }}{{ version_detail.version }}&nbsp;
      {{ $t(app_detail_4) }}{{ version_detail.compatibility }}{{ $t(app_detail_5) }}
    </p>
  </div>
</template>

<script>
import DownloadButton from '../components/DownloadButton.vue';
import PurchaseInAppButton from '../components/PurchaseInAppButton.vue';
import VersionDetail from '../../public/json/VersionDetail.json';
import WindowsButton from '../components/WindowsButton.vue';
import AppStoreButton from '../components/AppStoreButton.vue';

export default {
  name: 'FirstScreen',
  components: {
    AppStoreButton,
    WindowsButton,
    DownloadButton,
    // eslint-disable-next-line vue/no-unused-components
    PurchaseInAppButton,
  },
  data() {
    return {
      app_name: 'app_name',
      app_desc: 'app_description',
      app_detail_1: 'app_detail_1',
      app_detail_2: 'app_detail_2',
      app_detail_3: 'app_detail_3',
      app_detail_4: 'app_detail_4',
      app_detail_5: 'app_detail_5',
      version_detail: VersionDetail,
    };
  },
};
</script>

<style scoped lang="less">
  .first-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
    padding: 0 40px;
    margin: 0 auto;
    .app-logo {
      width: 2.56rem;
      height: 2.56rem;
      margin: 1.94rem 0 0.37rem;
    }
    .app-name {
      margin-bottom: 0.14rem;
      font-size: 0.44rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 0.53rem;
    }
    .app-desc {
      margin-bottom: 0.34rem;
      font-size: 0.18rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.21rem;
    }
    .button-box {
      display: flex;
      justify-content: center;
      margin-bottom: 0.32rem;
    }
    .app-detail {
      font-size: 0.14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.2rem;
      margin-bottom: 6.92rem;
      white-space: pre-wrap;
    }
  }
</style>
