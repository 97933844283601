<template>
  <div class="welcome-nav">
    <div class="left">
      <div class="nav-item">
        <img class="logo" :src="$t(logo)" alt="logo">
        <span class="logo-text">{{ $t(logo_text) }}</span>
      </div>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      logo: 'logo',
      logo_text: 'logo_text',
    };
  },
};
</script>

<style scoped lang="less">
  .welcome-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    height: 56px;
    margin: 0 auto;
    padding: 0 40px;
    .left {
      .nav-item {
        .logo, .logo-text {
          vertical-align: middle;
        }
        .logo {
          height: 28px;
          margin-right: 8px;
          line-height: 28px;
        }
        .logo-text {
          font-size: 16px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 22px;
        }
      }
    }
  }
</style>
