<template>
  <div class="home">
    <div class="nav-wrap" ref="nav_wrap">
      <Nav></Nav>
    </div>
    <div class="first-wrap">
      <first-screen></first-screen>
    </div>
    <div class="second-wrap">
      <second-screen></second-screen>
    </div>
    <div class="third-wrap">
      <third-screen></third-screen>
    </div>
    <div class="forth-wrap">
      <forth-screen></forth-screen>
    </div>
    <div class="fifth-wrap">
      <fifth-screen></fifth-screen>
    </div>
    <div class="footer-wrap">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>

import Nav from './Nav.vue';
import FirstScreen from './FirstScreen.vue';
import SecondScreen from './SecondScreen.vue';
import ThirdScreen from './ThirdScreen.vue';
import ForthScreen from './ForthScreen.vue';
import FifthScreen from './FifthScreen.vue';
import Footer from './Footer.vue';

export default {
  name: 'Home',
  components: {
    Footer,
    FifthScreen,
    ForthScreen,
    ThirdScreen,
    SecondScreen,
    FirstScreen,
    Nav,
  },
  data() {
    return {
      nav_scroll_event: null,
    };
  },
  mounted() {
    const navScrollHandler = () => {
      const scrollHeight = document.documentElement.scrollTop
        || document.body.scrollTop;
      const navWrapElement = this.$refs.nav_wrap;
      const baseHeight = 600;
      const transparent = scrollHeight > baseHeight ? 1 : scrollHeight / baseHeight;
      if (navWrapElement) {
        navWrapElement.style.backgroundColor = `rgba(9, 11, 22, ${transparent})`;
      }
    };
    this.nav_scroll_event = navScrollHandler;
    document.addEventListener('scroll', this.nav_scroll_event);
    this.$ba.trackEvent('pageView');
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.nav_scroll_event);
    this.nav_scroll_event = null;
  },
};
</script>

<style scoped lang="less">
  .home {
    background-color: #090B16;
  }
  .nav-wrap {
    position: fixed;
    width: 100%;
    z-index: 99;
    background-color: rgba(9, 11, 22, 0);
  }
  .first-wrap {
    background-image: url("../../public/image/bg_banner@2x.png");
    background-position: center 68px;
    background-repeat: no-repeat;
    background-size: 1440px 1335px;
  }
  .second-wrap {
    background-image: url("../../public/image/bg_up@2x.png");
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 1920px 1295px;
  }
  .forth-wrap {
    background-image: url("../../public/image/bg_down@2x.png");
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: cover;
  }
  .footer-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
</style>
