<template>
  <div class="forth-screen">
    <div class="function-4-box">
      <h1 class="function-4-title">{{ $t(function_4_title) }}</h1>
      <p class="function-4-desc">{{ $t(function_4_description) }}</p>
      <div class="function-4-detail">
        <ul class="detail-list">
          <li
            v-for="item in function_detail_list"
            :key="item.title"
            class="item">
            <img :src="$t(item.img)" alt="" class="item-img">
            <h3 class="item-title">{{ $t(item.title) }}</h3>
            <p class="item-desc">{{ $t(item.description) }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="comment-box">
      <h1 class="comment-title">{{ $t(comment_title) }}</h1>
      <div class="comment-list">
        <p
          v-for="(item, index) in comment_list"
          :key="index"
          class="comment-item">
          {{ $t(item.content) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForthScreen',
  data() {
    return {
      function_4_title: 'function_4_title',
      function_4_description: 'function_4_description',
      function_detail_list: [
        {
          img: 'function_4_detail_1_img',
          title: 'function_4_detail_1_title',
          description: 'function_4_detail_1_description',
        },
        {
          img: 'function_4_detail_2_img',
          title: 'function_4_detail_2_title',
          description: 'function_4_detail_2_description',
        },
        {
          img: 'function_4_detail_3_img',
          title: 'function_4_detail_3_title',
          description: 'function_4_detail_3_description',
        },
      ],
      comment_title: 'comment_title',
      comment_list: [
        {
          content: 'comment_1_content',
        },
        {
          content: 'comment_2_content',
        },
        {
          content: 'comment_3_content',
        },
        {
          content: 'comment_4_content',
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
  .forth-screen {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
    .function-4-box {
      margin: -2.66rem 0 2.5rem;
      text-align: left;
      .function-4-title {
        margin-bottom: 0.16rem;
        font-size: 0.44rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 0.62rem;
      }
      .function-4-desc {
        width: 7.56rem;
        max-width: 100%;
        margin-bottom: 0.8rem;
        font-size: 0.18rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.27rem;
        white-space: pre-wrap;
        word-break: break-word;
      }
      .function-4-detail {
        .detail-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item {
            display: flex;
            flex-direction: column;
            align-items: start;
            width: 3.86rem;
            max-width: 32.1%;
            height: 3.09rem;
            margin-right: 0.21rem;
            padding: 4.6% 3%;
            background: rgba(0, 15, 52, 0.5);
            border-radius: 0.16rem;
            border: 1px solid rgba(255, 255, 255, 0.3);
            backdrop-filter: blur(0.5rem);
            &:nth-child(2) {
              background: rgba(36, 0, 8, 0.5);
              border: 1px solid rgba(255, 255, 255, 0.3);
            }
            &:last-child {
              margin-right: 0;
            }
            .item-img {
              width: 0.9rem;
              max-width: 100%;
              margin-bottom: 0.2rem;
            }
            .item-title {
              margin-bottom: 0.16rem;
              font-size: 0.24rem;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 0.29rem;
            }
            .item-desc {
              height: 1.2rem;
              font-size: 0.16rem;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 0.2rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .comment-box {
      margin-bottom: 3.33rem;
      .comment-title {
        margin-bottom: 0.82rem;
        font-size: 0.44rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 0.53rem;
        text-align: right;
      }
      .comment-list {
        .comment-item {
          width: 6.8rem;
          max-width: 90%;
          margin-bottom: 0.39rem;
          font-size: 0.18rem;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 0.27rem;
          white-space: pre-wrap;
          word-break: break-word;
          &:nth-child(odd) {
            text-align: left;
          }
          &:nth-child(even) {
            margin-left: auto;
            margin-right: 0;
            text-align: right;
          }
        }
      }
    }
  }
</style>
