<template>
  <div class="first-screen">
    <img class="vip-logo" :src="$t(vip_logo)" alt="VIP logo">
    <h1 class="title">{{ $t(payment_title) }}</h1>
    <p class="detail">{{ $t(payment_detail) }}</p>
  </div>
</template>

<script>
export default {
  name: 'FirstScreen',
  data() {
    return {
      vip_logo: 'vip_logo',
      payment_title: 'payment_title',
      payment_detail: 'payment_detail',
    };
  },
};
</script>

<style scoped lang="less">
  .first-screen {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
    text-align: center;
    .vip-logo {
      height: 1.55rem;
      margin: 3.56rem 0 0.32rem;
    }
    .title {
      margin-bottom: 0.32rem;
      font-size: 24px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 0.33rem;
    }
    .detail {
      max-width: 3.52rem;
      margin: 0 auto;
      font-size: 0.15rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.75);
      line-height: 0.3rem;
    }
  }
</style>
