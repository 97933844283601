<template>
  <button
    class="app-store-button button"
    @click="jumpToAppStore">
    <img :src="$t(button_app_img)" alt="Apple download">
    <span class="button-text">
            <span class="app-store-text">{{ $t(app_store_text) }}</span>
            <span class="download-text">{{ $t(download_text) }}</span>
          </span>
  </button>
</template>

<script>
export default {
  name: 'AppStoreButton',
  data() {
    return {
      button_app_img: 'button_app_img',
      app_store_text: 'app_store_text',
      download_text: 'download_text',
    };
  },
  methods: {
    jumpToAppStore() {
      this.$ba.trackEvent('jumpToAppStore', 'click');
      this.$sensors.track('jumpToAppStore');
      window.open('https://apps.apple.com/app/fancyclean/id1587301004?mt=12', '_blank');
    },
  },
};
</script>

<style scoped lang="less">
  .app-store-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #DADADA, #FFFFFF);
    padding: 0 0.2rem;
    border-radius: 8px;
    cursor: pointer;
    img {
      height: 0.46rem;
      margin-right: 0.14rem;
    }
    .button-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: #1A1A1A;
      .app-store-text {
        font-size: 0.2rem;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 0.28rem;
      }
      .download-text {
        font-size: 0.14rem;
        line-height: 0.2rem;
      }
    }
  }
</style>
