import sensors from 'sa-sdk-javascript';
import getURLParam from '../utils/getURLParam';
import getUserOS from '../utils/getUserOS';
import performanceRecorder from '../utils/performanceRecorder';

export default {
  install(Vue, serverURL) {
    sensors.init({
      server_url: serverURL,
      is_track_single_page: true,
      use_client_time: true,
      send_type: 'beacon',
      heatmap: {
        clickmap: 'default',
        scroll_notice_map: 'not_collect',
      },
      show_log: process.env.NODE_ENV === 'development',
      preset_properties: {
        // 是否采集 $latest_utm 最近一次广告系列相关参数，默认值 true。
        latest_utm: true,
        // 是否采集 $latest_traffic_source_type 最近一次流量来源类型，默认值 true。
        latest_traffic_source_type: true,
        // 是否采集 $latest_search_keyword 最近一次搜索引擎关键字，默认值 true。
        latest_search_keyword: true,
        // 是否采集 $latest_referrer 最近一次前向地址，默认值 true。
        latest_referrer: true,
        // 是否采集 $latest_referrer_host 最近一次前向地址，1.14.8 以下版本默认是true，1.14.8 及以上版本默认是 false，需要手动设置为 true 开启。
        latest_referrer_host: false,
        // 是否采集 $latest_landing_page 最近一次落地页地址，默认值 false。
        latest_landing_page: false,
        // 是否采集 $url 页面地址作为公共属性，1.16.5 以下版本默认是 false，1.16.5 及以上版本默认是 true。
        url: true,
        // 是否采集 $title 页面标题作为公共属性，1.16.5 以下版本默认是 false，1.16.5 及以上版本默认是 true。
        title: true,
      },
    });
    sensors.registerPage({
      media: getURLParam('media'),
      campaign_id: getURLParam('campaign_id'),
      os: getUserOS(),
      first_paint: performanceRecorder.getFirstPaint(),
    });
    sensors.quick('autoTrack');
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$sensors = sensors;
  },
};
