export default function getURLParam(name) {
  const str = window.location.search.slice(1);
  const vars = str.split('&');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < vars.length; i++) {
    const [key, value] = vars[i].split('=');
    if (name === key) {
      return value;
    }
  }
  return null;
}
