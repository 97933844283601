<template>
  <div class="second-screen">
    <h1 class="function-title">{{ $t(function_title) }}</h1>
    <p class="function-desc">{{ $t(function_desc) }}</p>
    <img class="function-img" :src="$t(function_image)" alt="Function 1">
    <div class="function-detail-box">
      <div
        class="detail-item"
        v-for="(item, index) in detail_list"
        :key="index">
        <img class="img" :src="$t(item.image)" alt="Detail image">
        <div class="text-box">
          <h3 class="title">{{ $t(item.title) }}</h3>
          <p class="desc">{{ $t(item.desc) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondScreen',
  data() {
    return {
      function_title: 'function_1_title',
      function_desc: 'function_1_desc',
      function_image: 'function_1_image',
      detail_list: [
        {
          image: 'function_1_detail_1_image',
          title: 'function_1_detail_1_title',
          desc: 'function_1_detail_1_desc',
        },
        {
          image: 'function_1_detail_2_image',
          title: 'function_1_detail_2_title',
          desc: 'function_1_detail_2_desc',
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
  .second-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    .function-title {
      margin: -3.39rem 0 0.21rem;
      font-size: 0.44rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 0.53rem;
    }
    .function-desc {
      width: 7.56rem;
      max-width: 90%;
      margin-bottom: 0.8rem;
      word-break: break-word;
      white-space: pre-wrap;
      font-size: 0.18rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.27rem;
    }
    .function-img {
      width: 10.16rem;
      max-width: 90%;
      /*height: 6.11rem;*/
      margin-bottom: 1rem;
    }
    .function-detail-box {
      width: 12rem;
      max-width: 90%;
      margin-bottom: 3.92rem;
      padding: 8% 6%;
      background: rgba(0, 0, 39, 0.3);
      border-radius: 0.16rem;
      .detail-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.39rem;
        &:last-child {
          margin-bottom: 0;
        }
        .img {
          flex: 0 0 0.56rem;
          width: 0.56rem;
          height: 0.56rem;
          /*margin-right: 7%;*/
        }
        .text-box {
          flex: 0 0 72.5%;
          text-align: left;
          .title {
            margin-bottom: 0.12rem;
            font-size: 0.24rem;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 0.29rem;
          }
          .desc {
            width: 100%;
            white-space: pre;
            font-size: 0.16rem;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 0.24rem;
            word-break: break-all;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
</style>
