const getFirstPaint = function () {
  let time = -1;
  if ('performance' in window) {
    if (performance.timing.responseEnd === 0) {
      time = -1;
    } else {
      time = performance.timing.responseEnd - performance.timing.navigationStart;
    }
  }
  return time;
};
const getFirstContentfulPaint = function () {
  let time = -1;
  if ('performance' in window) {
    if (performance.timing.loadEventEnd === 0) {
      time = -1;
    } else {
      time = performance.timing.loadEventEnd - performance.timing.navigationStart;
    }
  }
  return time;
};
export default {
  getFirstPaint,
  getFirstContentfulPaint,
};
