<template>
  <button
    class="windows-button button">
    <img src="../../public/image/ic_button_apple@2x.png" alt="Apple icon">
    <span class="button-text">{{ $t(windows_text) }}</span>
  </button>
</template>

<script>
export default {
  name: 'WindowsButton',
  data() {
    return {
      windows_text: 'windows_text',
    };
  },
};
</script>

<style scoped lang="less">
  .button {
    height: 0.64rem;
    border-radius: 8px;
  }
  .windows-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    padding: 0 0.2rem;
    background-color: darkgrey;
    img {
      height: 0.46rem;
      margin-right: 0.14rem;
    }
    .button-text {
      font-size: 0.2rem;
      font-weight: 500;
      color: #242737;
      line-height: 0.33rem;
    }
  }
</style>
