<template>
  <div class="payment-reminder">
    <div class="nav-wrap">
      <Nav/>
    </div>
    <div class="first-wrap">
      <first-screen></first-screen>
    </div>
    <div class="footer-wrap">
      <Footer/>
    </div>
  </div>
</template>

<script>
import Nav from './Nav.vue';
import FirstScreen from './FirstScreen.vue';
import Footer from '../Footer.vue';

export default {
  name: 'LicenseUpgraded',
  components: { Footer, FirstScreen, Nav },
};
</script>

<style scoped lang="less">
  .payment-reminder {
    min-height: 100vh;
    background-image: url("../../../public/image/pic_pro@2x.png");
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: 1920px 1080px;
    .footer-wrap {
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
</style>
