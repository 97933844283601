let result = false;
let checked = false;

export default function () {
  if (checked) return result;
  const environment = process.env.NODE_ENV;
  if (environment === 'production') {
    result = /macintosh|mac os x/i.test(window.navigator.userAgent);
  } else {
    result = !/macintosh|mac os x/i.test(window.navigator.userAgent);
  }
  checked = true;
  return result;
}
