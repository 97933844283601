<template>
  <div class="welcome">
    <div class="nav-wrap">
      <Nav/>
    </div>
    <div class="first-wrap">
      <FirstScreen/>
    </div>
  </div>
</template>

<script>
import Nav from './Nav.vue';
import FirstScreen from './FirstScreen.vue';

export default {
  name: 'Welcome',
  components: { FirstScreen, Nav },
};
</script>

<style scoped lang="less">
  .welcome {
    background-color: #090B16;
    .nav-wrap {
      position: fixed;
      top: 0;
      z-index: 99;
      background-color: rgba(9, 11, 22, 0);
    }
    .first-wrap {
      height: 10.80rem;
      background-image: url("../../../public/image/bg_banner@2x.png");
      background-position: center 72px;
      background-repeat: no-repeat;
      background-size: 1440px 1335px;
    }
  }
</style>
