<!--suppress ES6ShorthandObjectProperty -->
<template>
  <div class="third-screen">
    <div class="function-2-box">
      <h1 class="function-2-title">{{ $t(function_2_title) }}</h1>
      <p class="function-2-desc">{{ $t(function_2_description) }}</p>
      <img
        class="function-2-img"
        src="../../public/image/pic_antivirus@2x.png"
        alt="Function image">
    </div>
    <div class="function-3-box">
      <h1 class="function-3-title">{{ $t(function_3_title) }}</h1>
      <div class="content-box">
        <img class="arrow-icon" :style="{ 'top': arrow_icon_top }" src="/image/ic_arrow@2x.png" alt="Arrow">
        <div class="left-box">
          <div class="option-list-box">
            <ul class="option-list">
              <li
                class="option-item"
                :class="{'selected-option-item': selected_option === item}"
                v-for="(item, index) in option_list"
                :key="item.title"
                @mouseover="selectOption(item, index)">
                <h3 class="option-title">{{ $t(item.title) }}</h3>
                <p class="option-desc">{{ $t(item.description) }}</p>
              </li>
            </ul>
          </div>
          <download-button v-if="$isMac()"></download-button>
          <windows-button v-if="!$isMac()"></windows-button>
        </div>
        <div class="right-box">
          <img
            v-for="(img, index) in option_image_list"
            v-show="index === selected_option_index"
            :key="img"
            class="option-img" :src="$t(img)" alt="Option image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadButton from '../components/DownloadButton.vue';
import WindowsButton from '../components/WindowsButton.vue';

export default {
  name: 'ThirdScreen',
  components: { DownloadButton, WindowsButton },
  data() {
    return {
      function_2_title: 'function_2_title',
      function_2_description: 'function_2_description',
      function_3_title: 'function_3_title',
      option_list: [
        {
          title: 'option_1_title',
          description: 'option_1_description',
        },
        {
          title: 'option_2_title',
          description: 'option_2_description',
        },
        {
          title: 'option_3_title',
          description: 'option_3_description',
        },
      ],
      // option_image_list: [
      //   '/image/pic_bigfiles@2x.png',
      //   '/image/pic_duplicatefiles@2x.png',
      //   '/image/pic_appmanager@2x.png',
      // ],
      option_image_list: [
        'option_1_image',
        'option_2_image',
        'option_3_image',
      ],
      selected_option: null,
      selected_option_index: null,
      arrow_icon_top: 0,
      // option_list: [
      //   {
      //     title: 'Big File',
      //     description: 'Find and remove large files on your computer to free up disk space',
      //   },
      //   {
      //     title: 'Duplicate Files',
      //     description: 'Add folders, quickly find files with the same content, save disk space',
      //   },
      //   {
      //     title: 'App Manager',
      // eslint-disable-next-line max-len
      //     description: 'Completely uninstall the application and reset the application to its initial state',
      //   },
      // ],
    };
  },
  methods: {
    selectOption(item, index) {
      this.selected_option = item;
      this.selected_option_index = index;
    },
  },
  watch: {
    selected_option_index: {
      immediate: true,
      handler() {
        console.log('watch selected_option_index');
        this.$nextTick(() => {
          const options = document.getElementsByClassName('option-item');
          const targetIndex = this.selected_option_index;
          const targetOption = options[targetIndex];
          const top = `calc(${0.26}rem + ${targetOption.offsetTop}px)`;
          this.arrow_icon_top = top;
        });
      },
    },
  },
  created() {
    // eslint-disable-next-line prefer-destructuring
    this.selected_option = this.option_list[0];
    this.selected_option_index = 0;
  },
};
</script>

<style scoped lang="less">
  .third-screen {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 40px;
    .function-2-box {
      margin: -1.42rem 0 1.95rem;
      text-align: right;
      .function-2-title {
        margin-bottom: 0.16rem;
        font-size: 0.44rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 0.62rem;
      }
      .function-2-desc {
        margin-bottom: 0.36rem;
        font-size: 0.18rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.27rem;
      }
      .function-2-img {
        width: 11.3rem;
        max-width: 90%;
      }
    }
    .function-3-box {
      margin-bottom: 5.16rem;
      text-align: left;
      .function-3-title {
        margin-bottom: 0.84rem;
        font-size: 0.44rem;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 0.62rem;
      }
      .content-box {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: start;
        .arrow-icon {
          position: absolute;
          left: -0.42rem;
          top: 0.26rem;
          width: 0.3rem;
          height: 0.3rem;
          transition: top ease-in-out 0.2s;
        }
        .left-box {
          max-width: 40%;
          text-align: left;
          .option-list-box {
            margin-bottom: 0.16rem;
            overflow: hidden;
            .option-item {
              cursor: pointer;
              .option-title {
                margin-bottom: 0.14rem;
                font-size: 0.24rem;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 0.29rem;
              }
              .option-desc {
                width: 3.7rem;
                max-width: 100%;
                margin-bottom: 0.43rem;
                font-size: 0.14rem;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 0.21rem;
                white-space: pre-wrap;
                word-break: break-word;
              }
              .option-title, .option-desc {
                transition: color ease-in-out 0.3s;
              }
            }
            .selected-option-item {
              .option-title {
                color: #6DE5FF;
              }
              .option-desc {
                color: #6DE5FF;
              }
            }
          }
        }
        .right-box {
          max-width: 60%;
          text-align: center;
          .option-img {
            width: 7.7rem;
            max-width: 90%;
            /*height: 4.65rem;*/
          }
        }
      }
    }
  }
</style>
