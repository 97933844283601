<template>
  <div class="first-screen">
    <h1 class="title">{{ $t(unlock_pro) }}</h1>
    <div class="function-list">
      <div
        class="function-item"
        v-for="(item) in function_list"
        :key="item.title">
        <img class="function-image" :src="$t(item.img)" alt="function image">
        <p class="function-title">{{ $t(item.title) }}</p>
        <p class="function-detail">{{ $t(item.detail) }}</p>
      </div>
    </div>
    <div class="button-box">
      <download-button v-if="$isMac()"></download-button>
      <purchase-in-app-button v-if="$isMac()"></purchase-in-app-button>
      <windows-button v-if="!$isMac()"></windows-button>
    </div>
  </div>
</template>

<script>
import DownloadButton from '../../components/DownloadButton.vue';
import WindowsButton from '../../components/WindowsButton.vue';
import PurchaseInAppButton from '../../components/PurchaseInAppButton.vue';

export default {
  name: 'FirstScreen',
  components: { PurchaseInAppButton, WindowsButton, DownloadButton },
  data() {
    return {
      unlock_pro: 'unlock_pro',
      function_list: [
        {
          img: 'function_4_detail_1_img',
          title: 'function_4_detail_1_title',
          detail: 'function_4_detail_1_detail',
        },
        {
          img: 'function_4_detail_2_img',
          title: 'function_4_detail_2_title',
          detail: 'function_4_detail_2_detail',
        },
        {
          img: 'function_4_detail_3_img',
          title: 'function_4_detail_3_title',
          detail: 'function_4_detail_3_detail',
        },
        {
          img: 'function_4_detail_4_img',
          title: 'function_4_detail_4_title',
          detail: 'function_4_detail_4_detail',
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
  .first-screen {
    max-width: 946px;
    min-width: 90%;
    margin: 0 auto;
    padding: 0 40px;
    text-align: center;
    .title {
      margin: 2.94rem 0 0.56rem;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.92);
      line-height: 0.5rem;
    }
    .function-list {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 946px;
      margin: 0 auto 0.48rem;
      .function-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        .function-image {
          height: 0.9rem;
          margin-bottom: 0.06rem;
        }
        .function-title {
          margin-bottom: 0.06rem;
          font-size: 0.17rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.92);
          line-height: 0.24rem
        }
        .function-detail {
          width: 1.6rem;
          font-size: 0.13rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.7);
          line-height: 0.18rem
        }
      }
    }
    .button-box {
      display: flex;
      justify-content: center;
      margin-bottom: 0.32rem;
    }
  }
</style>
