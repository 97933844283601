import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const titleInEN = 'FancyClean: The Better Mac Cleanup App for macOS. Get a Cleaner Mac for Free';
const titleInCN = '极光清理：更好的Mac清理应用。免费获得更清洁的Mac';
const descriptionInEN = 'Fancy Clean Mac is a Mac cleaner app to clean and optimize Mac. It is time to clean Mac and clean up hard drive to get more free space on your Macbook or iMac. Download the better cleanup app now for free!';
const descriptionInCN = '极光清理是一款 Mac 清理应用程序，用于清洁和优化 Mac。 是时候清理 Mac 并清理硬盘，以便在 Macbook 或 iMac 上获得更多可用空间。 立即免费下载更好的清理应用程序！';
const websiteNameInEN = 'FancyClean';
const websiteNameInCN = '极光清理';

export default new Vuex.Store({
  state: {
    language: '',
  },
  mutations: {
    setLanguage(state, payload) {
      state.language = payload.language;
      const targetTitle = state.language.indexOf('en') !== -1 ? titleInEN : titleInCN;
      document.title = targetTitle;
      const ogTitleMeta = document.querySelector('meta[property=og\\:title]');
      ogTitleMeta.setAttribute('content', targetTitle);
      const descriptionMeta = document.querySelector('meta[name=description]');
      const ogDescriptionMeta = document.querySelector('meta[property=og\\:description]');
      const targetDescription = state.language.indexOf('en') !== -1 ? descriptionInEN : descriptionInCN;
      descriptionMeta.setAttribute('content', targetDescription);
      ogDescriptionMeta.setAttribute('content', targetDescription);
      const targetWebsiteName = state.language.indexOf('en') !== -1 ? websiteNameInEN : websiteNameInCN;
      const ogWebsiteMeta = document.querySelector('meta[property=og\\:site_name]');
      ogWebsiteMeta.setAttribute('content', targetWebsiteName);
    },
  },
  actions: {
  },
  modules: {
  },
});
