<template>
  <div class="welcome-nav">
    <div class="left">
      <h2 class="title">{{ $t(welcome_top_title) }}</h2>
    </div>
    <div class="right"></div>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data() {
    return {
      welcome_top_title: 'welcome_top_title',
    };
  },
};
</script>

<style scoped lang="less">
  .welcome-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    height: 56px;
    margin: 0 auto;
    padding: 0 40px;
    .left {
      .title {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #819DFF;
      }
    }
  }
</style>
